import React, {useState} from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Footer from "../components/footer";
import Header from "../components/header2";

import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import { store } from 'react-notifications-component';

import "../styles/index.css";
import "../styles/contact.css";
import "../styles/responsive.css";

import Success from '../images/Success.svg';
import Error from '../images/Error.svg';



const headerStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0
};

const Contact = () => {

  const [status, setStatus] = useState("");

  const notify = (notif) => {
    store.addNotification({
      title: "Fix input.",
      message: notif,
      type: "default",
      insert: "bottom",
      container: "bottom-left",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    });
  }
  const validate = (data) => {
    let name = data["name"];
    let email = data["email"];
    let subject = data["subject"];
    let message = data["message"];
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
    var notif = "";
    
    if (name.length <2 ) {
      notif = "Name must be at least 2 characters long. ";
      notify(notif);
    }
    if (subject.length <2 ) {
      notif = "Subject must be at least 2 characters long. ";
      notify(notif);
    }
    if (message.length <2 ) {
      notif = "Message must be at least 2 characters long. ";
      notify(notif);
     
    }
    if (!re.test(String(email).toLowerCase())){
      notif = "Invalid email address. \n";
      notify(notif);
      
  }
  if (notif.length===0)  return true;
  
  return false;
}
  const submitForm = (ev) => {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    if (validate(Object.fromEntries(data))){
      
      const xhr = new XMLHttpRequest();
      xhr.open(form.method, form.action);
      xhr.setRequestHeader("Accept", "application/json");
      
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== XMLHttpRequest.DONE) return;
        if (xhr.status === 200) {
          form.reset();
          setStatus("SUCCESS");
          
        } else {
          
          setStatus("ERROR");
        }
      };
      xhr.send(data);
    }
    
    
  }

  
  return (
  <Layout>
    <SEO title="Contact" keywords={["bixbit", "web-dev", "iot-solutions"]} />
    <ReactNotification />
    <div>
      <Header className="container" style={headerStyle} dark selected="contact" />
      <div className="left-right-even">
        <div className="left-side-form">
        {status === "" && 
          <form 
            className="contact-form" 
            onSubmit={submitForm}
            action="https://formspree.io/f/xgepdyoy"
            method="POST">
            <input className="contact-input" placeholder="Name" name="name" type="text"/>
            <input className="contact-input" placeholder="E-mail" name="email" type="email"/>
            <input className="contact-input" placeholder="Subject" name="subject" type="text"/>
            <textarea placeholder="Message" name="message" type="text"/>
            {status === "SUCCESS" ? "" :<input
              type="submit"
              className="contact-submit default-btn"
              value="Contact us "
            />}
            
          </form>}
          {status === "SUCCESS" && 
            <div className="succ">
              <img src={Success} alt=""/>
              <p className="success">Message succesfully sent</p>
            </div>}
          {status === "ERROR" && 
          <div className="succ">
            <img src={Error} alt=""/>
            <p className="error">Message not delivered</p>
            <p role="presentation" className="try" onClick={()=>setStatus("")} onKeyUp={()=>void 0}>Try again</p>
          
          </div>}
          
          
         
        </div>
        <div className="contact-bg"></div>
      </div>
      <Footer style={{ marginTop: "0" }} withContainer />
    </div>
  </Layout>
);

  }
export default Contact;
